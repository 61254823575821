import { render, staticRenderFns } from "./FullPageFooter.vue?vue&type=template&id=05392742&scoped=true&"
import script from "./FullPageFooter.vue?vue&type=script&lang=js&"
export * from "./FullPageFooter.vue?vue&type=script&lang=js&"
import style0 from "./FullPageFooter.vue?vue&type=style&index=0&id=05392742&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05392742",
  null
  
)

export default component.exports