<!--
 * @Author: your name
 * @Date: 2020-10-19 15:58:31
 * @LastEditTime: 2020-10-22 10:57:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\layouts\AuthLayouts\AuthLayout.vue
-->
<template>
  <div>
    <div v-if="!blankPage" class="bg-white mobile_login">
      <Loader />
      <section :style="signInPageHeight">
        <div class="no-gutters">
          <img src="../../assets/images/login/3.png" alt="" class="banner_img">
          <img src="../../assets/images/login/logo.png" alt="" class="logo_img">
          <!-- <div class="col_6">
            <img
              :style="imgHeight"
              src="../../assets/images/login/1.png"
              alt=""
            />
          </div>
          <div class="col_4 content_mo">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div> -->
          <div class="login_container">
            <h1 class="text">{{ text }}</h1>
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </section>
      <FullPageFooter />
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
  import Loader from "../../components/socialvue/loader/Loader";
  import logo from "../../assets/images/logo-white.png";
  import { socialvue } from "../../config/pluginInit";
  import bgImage from "../../assets/images/login/login-2.jpg";
  // 引入页脚
  import FullPageFooter from "../../components/ComComponents/FullPageFooter";

  export default {
    name: "AuthLayout1",
    components: {
      Loader,
      FullPageFooter,
    },
    mounted() {
      socialvue.index();
    },
    computed: {
      blankPage() {
        let blackPageList = ["auth1.watch-video"];
        return blackPageList.includes(this.$route.name);
      },
    },
    data() {
      return {
        signInPageHeight: "",
        slickOptions: {
          slidesToShow: 1,
          arrows: false,
        },
        logo: logo,
        bgImageURL: bgImage,
        imgHeight: "",
        text: "",
      };
    },
    watch: {
      $route: {
        deep: true,
        immediate: true,
        handler: function (val, oldVal) {
          if (val.fullPath === "/auth/sign-up1") {
            this.text = "Register";
          } else if (val.fullPath === "/auth/2fa-reset") {
            this.text = "Reset 2fa";
          } else if (val.fullPath === "/auth/sign-in1") {
            this.text = "Sign in";
          } else if (val.fullPath === "/auth/delete-account") {
            this.text = "Delete Account";
          } else {
            this.text = "";
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import url("../../assets/css/custom.css");
  @import url("../../assets/css/PriceSlider.css");

  .no-gutters {
    position: relative;

    .banner_img {
      height: 100vh;
    }

    .logo_img {
      position: fixed;
      top: 35px;
      left: 70px;
      width: 160px;
    }

    .login_container {
      padding-top: 35px;
      padding-bottom: 80px;
      height: 100vh;
      right: 0;
      top: 0;
      position: fixed;
      background-color: #fff;
      width: 50%;
      overflow: auto;
    }

    h1.text {
      display: none;
    }
  }

  .footerStyle {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 2;
  }

  .bg-white {
    background-color: #fff !important;
    overflow-x: hidden;
  }

  @media (max-width: 768px) {
    .login_container {
      padding-top: 10px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .login_container {
      padding-top: 30px !important;
    }
  }

  @media (min-width: 579px) {
    ::v-deep .sign_up {
      align-items: flex-end;
    }
  }

  @media (max-width: 578px) {
    .no-gutters {
      position: relative;

      h1.text {
        display: block;
        z-index: 2;
        margin-left: 20px;
        margin-top: 10px;
        font-size: 2rem;
      }

      .login_container {
        width: 100%;
        height: 100%;
      }
    }

    ::v-deep h1.title {
      display: none;
    }

    ::v-deep .sign-info {
      padding-bottom: 30px;
    }

    .footerStyle {
      position: fixed;
      bottom: 0;
    }

    ::v-deep .sign_up {
      flex-direction: column;

      button {
        margin-left: auto;
      }
    }
  }
</style>

<style lang="scss">
  @media (max-width: 578px) {
    .el-message-box {
      width: 86%; 
    }
  }
</style>