<template>
  <!-- Footer -->
  <footer class="bg-white footerStyle">
    <div class="container-fluid">
      <div class="row py-2 py-md-0">
        <div class="col-md-9 d-flex align-items-center">
          <div
            class="row d-flex justify-content-center justify-content-md-start"
          >
            <div
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-md-start
              "
            >
              <img
                class="mr-2"
                src="../../assets/images/logo-blue.png"
                alt=""
              />
              <div class="mr-4">©{{ new Date().getFullYear() }}</div>
            </div>
            <ul class="list-inline mb-0 mt-2 mt-sm-0">
              <li class="list-inline-item">About</li>
              <li class="list-inline-item">
                <router-link
                  :to="{ path: '/privacyAndPolicyAndUser', query: { id: 1 } }"
                >
                  User Agreement</router-link
                >
              </li>
              <li class="list-inline-item">
                <router-link
                  :to="{ path: '/privacyAndPolicyAndUser', query: { id: 2 } }"
                >
                  Privacy Policy</router-link
                >
              </li>
              <li class="list-inline-item">
                <router-link
                  :to="{ path: '/privacyAndPolicyAndUser', query: { id: 3 } }"
                >
                  Payment Policy</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 d-flex justify-content-end selLang">
          <div>
            <select @change="selectLang" class="form-control birthdaySelect">
              <option
                v-for="(v, i) in languageList"
                :key="i"
                :value="v.lang"
                :selected="langType == v.lang"
              >
                {{ v.language }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer END -->
</template>
<script>
export default {
  name: 'FooterStyle1',
  data () {
    return {
      languageList: [],
      langType: ""
    }
  },

  created () {
    this.getWebLanguageList();
    // 刷新页面以后将本地的langtype赋值
    let langType = localStorage.getItem('langType');
    this.langType = langType ? langType : 'en'
  },
  methods: {

    async getWebLanguageList () {
      let res = await this.$http.getWebLanguageList();
      this.languageList = res.data;
    },
    async selectLang (v) {
      this.$i18n.locale = 'lang';
      let res = await this.$http.getWebLanguageData({
        lang: v.target.value
      })
      localStorage.setItem('lang', res.data)
      localStorage.setItem('langType', v.target.value)
      this.$i18n.setLocaleMessage("lang", (JSON.parse(res.data)))
    },
  }
}
</script>
<style lang="scss" scoped>
.footerStyle {
  min-height: 6.9vh;
  display: flex;
  align-items: center;
  box-shadow: inset 0px 15px 10px -15px rgba(44, 101, 144, 0.1);

  .birthdaySelect {
    border: 0;
  }

  .list-inline {
    .list-inline-item > a {
      color: #999;

      &:hover {
        color: #50b5ff;
      }
    }
  }
}
@media (max-width: 600px) {
  .selLang {
    position: absolute;
    right: 0;
    top: 0;
  }
}

</style>